import React from 'react';
import './QuestionsTable.css';

const NewQuestionsTable = ({ questionText, questions, answers, selectedOptions, onOptionChange }) => {
    return (
        <div className="questionnaire-container">
            {questionText ? <h3>{questionText}</h3> : null}
            <table className="questionnaire-table">
                <thead>
                <tr>
                    {answers.map((answer, index) => (
                        <th key={index}>{answer}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {questions.map((question) => (
                    <tr key={question}>
                        {answers.map((answer) => (
                            <td key={answer}>
                                <input
                                    type="radio"
                                    name={question}
                                    value={answer}
                                    checked={selectedOptions[question] === answer}
                                    onChange={() => onOptionChange(question, answer)}
                                />
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default NewQuestionsTable;
