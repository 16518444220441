import { createChatBotMessage } from 'react-chatbot-kit';
import FeatureFirst from '../widgets/FeatureFirst';
import FeatureSecond from '../widgets/FeatureSecond';
import Headphones from '../widgets/Headphones';
import LastMessage from '../widgets/LastMessage';
import BotAvatar from './BotAvatar';
import {SimpleBot} from './SimpleBot';
import CustomBotMessage from './CustomBotMesage';
import Frequency from '../widgets/Frequency';
import FinishFirst from '../widgets/FinishFirst';
import FinishSecond from '../widgets/FinishSecond';
import AdditionalQuestionsQuestion from '../widgets/AdditionalQuestionsQuestion';

const Bot = new SimpleBot()
const config = {
    initialMessages: [createChatBotMessage(Bot.hello().message, {
        delay:0,
        withAvatar: false
    })],
    botName: 'Chat bot',
    customComponents: {

        botAvatar: (props) => <BotAvatar {...props} />,
        botChatMessage: (props) => <CustomBotMessage {...props} />,

    },
    widgets: [
        {
            widgetName: 'feature1',
            widgetFunc: (props) => <FeatureFirst {...props} />,
            // mapStateToProps: ['messages'],
        },
                {
            widgetName: 'feature2',
            widgetFunc: (props) => <FeatureSecond {...props} />,
            // mapStateToProps: ['messages'],
        },
        {
            widgetName: 'headphones',
            widgetFunc: (props) => <Headphones {...props} />,
            // mapStateToProps: ['messages'],
        },
        {
            widgetName: 'lastMessage',
            widgetFunc: (props) => <LastMessage {...props} />,
            // mapStateToProps: ['messages'],
        },
        {
            widgetName: 'frequencyResponseWidget',
            widgetFunc: (props) => <Frequency {...props} />,
        },
        {
            widgetName: 'finishFirstWidget',
            widgetFunc: (props) => <FinishFirst {...props} />,
        },
        {
            widgetName: 'additionalQuestionsWidget',
            widgetFunc: (props) => <AdditionalQuestionsQuestion {...props} />,
        },
        {
            widgetName: 'finishSecondWidget',
            widgetFunc: (props) => <FinishSecond {...props} />,
        },


    ],
    state: {
        productChoosedByBot: null,
        userLastMessage: null,
        botLastMessage: null,
        messageToRepeat: null,
        questionnaireFinished: false,
        type: '',
        name: '',
        userAnswers: {
            name:'-',
            batteryLife:null,
            // chargeTime: null,
            weight: null,
            // noiseCancellingType: null,
            noiseCancellationQuality: null,
            // foldable: null,
            bluetoothRange: null,
            // frequencyResponse: null,
            androidControl:null,
            // googleAssistant: null,
            iosControl: null,
            // amazonAlexa: null,
            // warranty: null,
            price: null,
            feature1: null,
            feature2: null,
            wantToSeeOtherHeadphones: null,
            joke: false,
            interactionEnjoyable: null,
            processPleasant: null,
            interactionFun:null,
            botBelievable: null,
            botFair: null,
            botAccurate: null,
            botReliable:null,
            interactionRating: null,
            credibilityRating: null,
            interactionEnjoyableDescription: '',
            credibilityRatingDescription: ''
        },

    },
    delay:0,

};

export default config;
