//  Previous links
// Human-like: https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_bxx9gvRXwRz46qi
// Talking-head-f: https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_6hZuhAOPvwM3yzY
// Talking-head-m: https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_6ujJf1WgyeI15bM
// Machine-like: https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_3wvxxB55Jcuf5f8

export const getSurveyLink = (botType) => {
  switch (botType) {
    case "human-like":
      return "https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_3g8ZKQ7LiBOwEui";
    case "talking-head-f":
      return "https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_1QUgqxu7MMN0dzU";
    case "talking-head-m":
      return "https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_1QUgqxu7MMN0dzU";
    default:
      return "https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_ebWlrWIEwt7sUzI";
  }
};
