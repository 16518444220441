import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAdditionalAnswers } from "../../../../store/slices/user-slice"; // Ensure you import the updated component
import "./QuestionsTable.css";
import { Modal } from "react-bootstrap";
import NewQuestionsTable from "./NewQuestionsTable";
import './NewQuestionsTable.css';

const answers = [
  "Very low",
  "Low",
  "Somewhat low",
  "Neither low nor high",
  "Somewhat high",
  "High",
  "Very high",
];

const NewQuestionnaire = ({
  botType,
  isOpened,
  setIsOpened,
  setBotState,
  botState,
}) => {
  const dispatch = useDispatch();

  const botName = botType === "machine-like" ? "the virtual advisor" : "Anna";

  const questionText1 = `How would you rate your overall enjoyment of interacting with ${botName}?`;
  const questions1 = [questionText1];

  const questionText2 = `How would you rate the credibility of  ${botName}?`;
  const questions2 = [questionText2];

  // Global state to manage answers for all questions
  const [selectedOptions, setSelectedOptions] = useState({
    ...questions1.reduce((acc, question) => {
      acc[question] = null;
      return acc;
    }, {}),
    ...questions2.reduce((acc, question) => {
      acc[question] = null;
      return acc;
    }, {}),
  });

  const [interactionEnjoyableDescription, setInteractionEnjoyableDescription] =
    useState("");
  const [credibilityRatingDescription, setCredibilityRatingDescription] =
    useState("");

  console.log("selectedOptions", {
        interactionRating: selectedOptions[questions1[0]],
        credibilityRating: selectedOptions[questions2[0]],
        interactionEnjoyableDescription,
        credibilityRatingDescription
      });

  const handleOptionChange = (question, answer) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [question]: answer,
    }));
  };

  const finishQuestionnaire = () => {
    setBotState({
      ...botState,
      questionnaireFinished: true,
      userAnswers: {
        ...botState.userAnswers,
        interactionRating: selectedOptions[questions1[0]],
        credibilityRating: selectedOptions[questions2[0]],
        interactionEnjoyableDescription,
        credibilityRatingDescription
      },
    });
    dispatch(
      setAdditionalAnswers({
        interactionRating: selectedOptions[questions1[0]],
        credibilityRating: selectedOptions[questions2[0]],
        interactionEnjoyableDescription,
        credibilityRatingDescription
      }),
    );
    setIsOpened(false);
  };

  const buttonDisabled = !Object.values(selectedOptions).every((el) => !!el) || !interactionEnjoyableDescription || !credibilityRatingDescription;

  return (
    <Modal
      show={isOpened}
      className={`${isOpened ? "openedQuestions" : "closedQuestions"}`}
    >
      <div className="d-flex flex-column w-100">
        <NewQuestionsTable
          questionText={questionText1}
          questions={questions1}
          answers={answers}
          selectedOptions={selectedOptions}
          onOptionChange={handleOptionChange}
        />
        <input
          id="interactionEnjoyableDescription"
          className="input"
          value={interactionEnjoyableDescription}
          onChange={(event) =>
            setInteractionEnjoyableDescription(event.target.value)
          }
          placeholder="Can you please briefly explain why?"
        />

        <NewQuestionsTable
          questionText={questionText2}
          questions={questions2}
          answers={answers}
          selectedOptions={selectedOptions}
          onOptionChange={handleOptionChange}
        />

        <input
          id="credibilityRatingDescription"
          className="input"
          value={credibilityRatingDescription}
          onChange={(event) =>
            setCredibilityRatingDescription(event.target.value)
          }
          placeholder="Can you please briefly explain why?"
        />

        <button
          className={`button border border-1 border-opacity-25 border-dark rounded-1 button-white-bg mb-10`}
          style={{
            width: "calc(30% - 20px)",
            marginLeft: "20px",
            padding: "10px",
            backgroundColor: `${buttonDisabled ? "#ccc" : "#3D85C7"}`,
            color: "white",
          }}
          disabled={buttonDisabled}
          onClick={finishQuestionnaire}
        >
          Take me back to the conversation page
        </button>
      </div>
    </Modal>
  );
};

export default NewQuestionnaire;
