// import {act} from 'react-dom/test-utils';

const {createSlice} = require('@reduxjs/toolkit');

const versions = ['L', 'P']
const botTypes = [
    //'human-like',
    //'talking-head-f',
    // 'talking-head-m',
    'machine-like'
]
const groups = [
    // 'b0',
    'b1',
    'b2',
    'b3'
]
const filters=['with filters and sorting', 'without filters and sorting']
const userSlice = createSlice({
    name: "user",
    initialState: {
        profilicId: "",
        frequencyOfShopping: "",
        country: '',
        agreeToParticipate: false,
        name: '',
        botType: 'talking-head-f',
        version: '',//L, P
        group: 'b0', //b0, b1, b2, b3,
        filter: null,
        userAnswers: {
            name: '-',
            batteryLife: null,
            chargeTime: null,
            weight: null,
            noiseCancellingType: null,
            noiseCancellationQuality: null,
            foldable: null,
            bluetoothRange: null,
            frequencyResponse: null,
            androidControl: null,
            googleAssistant: null,
            iosControl: null,
            amazonAlexa: null,
            warranty: null,
            price: null,
            feature1: null,
            feature2: null,
            wantToSeeOtherHeadphones: null,
            joke: null,
            interactionEnjoyable: null,
            processPleasant: null,
            interactionFun:null,
            botBelievable: null,
            botFair: null,
            botAccurate: null,
            botReliable:null,
            interactionRating: null,
            credibilityRating: null,
            interactionEnjoyableDescription: '',
            credibilityRatingDescription: ''

        },
        product: null,
        chosenProduct: null,
        messages: []
    },
    reducers: {
        setUser(state, action) {
            const {profilicId, frequencyOfShopping, country, agreeToParticipate, name} = action.payload;
            const botType = botTypes[Math.floor(Math.random() * botTypes.length)]
            const group = groups[Math.floor(Math.random() * groups.length)]
            const filter = filters[0] //filters[Math.floor(Math.random() * filters.length)]
            const getVersion = () => {

                switch (group) {
                    case group[1]:
                    case group[2]: {
                        if (botType === botTypes[0]) {
                            return versions[0]
                        } else {
                            return versions[1]
                        }
                        break
                    }
                    case group[3]: {
                        return versions[Math.floor(Math.random() * versions.length)]
                        break;
                    }
                    default: {
                        return 'NA'
                        break;
                    }
                }
            }
            const version = getVersion()


            return state = {
                ...state,
                profilicId,
                frequencyOfShopping,
                country,
                agreeToParticipate,
                name: name || '',
                botType,
                group,
                version,
                filter
            };
        },
        setUserAnswers(state, action) {

            state.userAnswers = action.payload

        },
        setAdditionalAnswers(state, action) {
            state.userAnswers={...state.userAnswers, ...action.payload}
        },
        setUserAnswer(state, action) {
            state.userAnswers = {...state.userAnswers, ...action.payload}
        },
        setBotSettings(state, action) {
            return state = {...state, ...action.payload}
        },
        setRecommendedProduct(state, action) {

            state.product = action.payload

        },
        setChosenProduct(state, action) {

            state.chosenProduct = action.payload

        },
        setMessages(state, action) {

            state.messages = action.payload

        },
        clearStore(state, action) {
            return state = {
                profilicId: "",
                frequencyOfShopping: "",
                country: '',
                agreeToParticipate: null,
                name: '',
                botType: '',
                version: '',//L, P
                group: '', //b0, b1, b2, b3,
                filter: null,
                userAnswers: {
                    name: '-',
                    batteryLife: null,
                    chargeTime: null,
                    weight: null,
                    noiseCancellingType: null,
                    noiseCancellationQuality: null,
                    foldable: null,
                    bluetoothRange: null,
                    frequencyResponse: null,
                    androidControl: null,
                    googleAssistant: null,
                    iosControl: null,
                    amazonAlexa: null,
                    warranty: null,
                    price: null,
                    feature1: null,
                    feature2: null,
                    wantToSeeOtherHeadphones: null,
                    joke: null,
                    interactionEnjoyable: null,
                    processPleasant: null,
                    interactionFun:null,
                    botBelievable: null,
                    botFair: null,
                    botAccurate: null,
                    botReliable:null,
                    interactionRating: null,
                    credibilityRating: null,
                    interactionEnjoyableDescription: '',
                    credibilityRatingDescription: ''

                },
                product: null,
                chosenProduct: null,
                messages: []
            }
        }
    },
});

export const {
    setUser,
    setUserAnswers,
    setBotSettings,
    setRecommendedProduct,
    setUserAnswer,
    setChosenProduct,
    setMessages,
    clearStore,
    setAdditionalAnswers
} = userSlice.actions;
export default userSlice.reducer;
